<template>
  <div style="width: 100%;">
    <v-row>
      <v-col v-if="isAllowed('device','c')" cols="4">
        <v-container>
          <v-row>
            <v-col class='d-flex flex-row align-center'>
              <h1>Add New {{this.singular}}</h1>
            </v-col>
            <v-col>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Name"
                  outlined
                  v-model="data.name"
              />
              <v-text-field
                  label="Location"
                  outlined
                  v-model="data.location"
              />
              <v-text-field
                  label="Serial"
                  outlined
                  v-model="data.serial"
              />
              <v-select
                  item-text="name"
                  item-value="id"
                  :items="allData.deviceTypes"
                  label="Device Type"
                  outlined
                  clearable
                  v-model="data.deviceType"
              />
              <span v-if="this.data && this.data.deviceType==1">
                <v-chip
                    v-for="type in allData.documentTypes" :key="type.id"
                    class="ma-2"
                >
                  {{type.name}} - {{type.id}}
                </v-chip>
              </span>
              <v-textarea
                  name="Device Data"
                  label="Data"
                  v-model="data.data"
                  outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="create" :disabled="!this.data.name">Create</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col>
        <v-container>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{this.plural}}</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="allData.devices"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="rowClick"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";

  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          { text: 'ID', value: 'id' },
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Location', value: 'location' },
          { text: 'Type', value: 'type' },
          { text: 'Serial', value: 'serial' },
        ],
        editMode: false,
        data: {
          name: "",
          location: "",
          serial: "",
          documentTypes: [],
          data: '',
          metadata: ''
        },
        allData: {},
        singular: "Device",
        singularLower: "device",
        plural: "Devices",
        pluralLower: "devices"
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed'])
    },
    methods: {
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async create(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Created 🎉`, "success");

          this.data={
            name: "",
            location: "",
            serial: "",
            documentTypes: [],
            data: {},
            metadata: {}
          };
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async getAllData(){
        try {
          this.loader = true;
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(res.data.error) throw res.data.error

          this.allData = res.data.data
          //expecting from backend: res: {devices, deviceTypes, documentTypes}
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      }
    },
  }
</script>
