<template>
  <div class="" style="width: 100%;">
    <div class='d-flex flex-row align-center justify-start'>
      <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
      <h2>Customer: {{this.cus.name}}</h2>
      <v-progress-circular
        indeterminate
        color="green"
        v-if="loader"
        style="margin-left: 10px;"
      ></v-progress-circular>
    </div>
    <v-col v-if="!this.loader">
      <h2>Account Summary</h2>
      <span class="d-flex flex-row">
        <span class="d-flex flex-column">
          <span v-if="this.cus.Orders">Total Orders: {{this.cus.Orders.length}}</span>
          <span v-if="this.cus.Orders">Total Purchases: {{utils.formatPrice(this.cus.Orders.reduce((acc,x)=>acc+(x.metadata.grandTotal||0),0))}}</span>
        </span>
      </span>
    </v-col>
    <span class="d-flex flex-column jusutify-center mt-5">
      <h2>Orders</h2>
      <p v-if="cus && cus.Orders && cus.Orders.length < 1">This customer has no orders.</p>
    </span>
    <v-row>
      <v-col v-if="!loader">
        <v-card outlined :key="i" v-for="(order, i) in cus.Orders" class="mb-2 d-flex flex-row align-center">
          <orderItem :order="order" :customer="cus.name"/>
        </v-card>
      </v-col>
    </v-row>
    <!-- <span class="d-flex flex-column jusutify-center mt-5">
      <h2>Payments</h2>
      <p v-if="cus && cus.Orders && cus.Orders.length < 1">This customer has no orders.</p>
    </span>
    <v-row>
      <v-col v-if="!loader">
        <v-card outlined :key="i" v-for="(order, i) in cus.Orders" class="mb-2 d-flex flex-row align-center">
          <orderItem :order="order" :customer="cus.name"/>
        </v-card>
      </v-col>
    </v-row> -->
    <span class="d-flex flex-row align-center mt-5">
      <h2>
        Customer Info 
        <v-btn v-if="!this.showCustomerInfo" class="ml-2" @click="()=>this.showCustomerInfo = true" fab x-small color="info"><v-icon>mdi-chevron-down</v-icon></v-btn>
        <v-btn v-else class="ml-2" @click="()=>{this.showCustomerInfo = false; this.isEditable = false}" fab x-small color="error"><v-icon>mdi-chevron-up</v-icon></v-btn>  
      </h2>
    </span>
    <span v-if="this.showCustomerInfo">
      <v-form class="mt-5" :disabled="!isEditable">
        <v-text-field
            dense
            label="Name"
            outlined
            v-model="cus.name"
        />
        <v-text-field
            dense
            label="Phone"
            outlined
            v-model="cus.phone"
        />
        <v-text-field
            dense
            label="Email"
            outlined
            v-model="cus.email"
        />
      </v-form>
      <v-row>
        <v-col>
          <v-btn small color="info" v-if="isAllowed('customer', 'u')" @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit Customer Details</span>
          </v-btn>
          <span v-if="isEditable">
            <v-btn class="mx-2" color="success" @click="updateCustomer">Save</v-btn>
            <v-btn v-if="isAllowed('customer', 'd')" class="error" @click="deleteCustomer">Delete</v-btn>
          </span>
        </v-col>
      </v-row>
      <span class="d-flex flex-row align-center mt-5 mb-2">
        <h2>Addresses</h2>
        <v-btn @click="saveCustomAddressDialog=true" fab small class="ml-2" color="info"><v-icon>mdi-plus</v-icon></v-btn>
      </span>
      <v-card v-for="(addr, i) in cus.addresses" class="d-flex flex-column" outlined :key="i">
        <v-card-title class="d-flex flex-row justify-space-between">
          <span>{{addr.name}}</span>
          <v-btn v-if="isAllowed('customer', 'u')" fab x-small class="error" @click="deleteAddress(i)"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <v-text-field label="Address Line 1" v-model="addr.line1" outlined dense class="mx-2"/>
          <v-text-field label="Address Line 2" v-model="addr.line2" outlined dense class="mx-2"/>
          <v-text-field label="City" v-model="addr.city" outlined dense class="mx-2"/>
          <v-text-field label="Country" v-model="addr.country" outlined dense class="mx-2"/>
        </v-card-text>
      </v-card>
    </span>
    <v-dialog scrollable v-model="saveCustomAddressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Save Address
        </v-card-title>
        <v-card-text>
          <v-text-field type="text" dense outlined label="Name of Address" v-model="newAddress.name"/>
          <v-text-field label="Address Line 1" v-model="newAddress.line1" outlined dense class="mx-2"/>
          <v-text-field label="Address Line 2" v-model="newAddress.line2" outlined dense class="mx-2"/>
          <v-text-field label="City" v-model="newAddress.city" outlined dense class="mx-2"/>
          <v-text-field label="Country" v-model="newAddress.country" outlined dense class="mx-2"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomAddressDialog">
            Cancel
          </v-btn>
          <v-btn :disabled="!newAddress.name" class="success" text @click="saveCustomAddress">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import orderItem from "../../components/orderItem.vue"
  import utils from '../../plugins/helpers'
  export default {
    components: {
      orderItem
    },
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        showCustomerInfo: false,
        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        cus: {
          contact: {},
          addresses: []
        },
        isEditable: false,
        saveCustomAddressDialog: false,
        newAddress: {}
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/customers/withorders/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          this.cus = res.data.data
          console.log(this.cus)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async updateCustomer(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/customers/${this.$route.params.id}`, this.cus)
          if(res.data.error) throw res.data.error

          if(res.data.success){
            this.snack("Customer Updated 🎉", "success");
            this.isEditable = false;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteCustomer(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/customers/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack("Customer Deleted 🎉", "success");
          await this.$router.go(-1)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async saveCustomAddress(){
        try{
          let res = await axios.put(`${this.getEndpoint}/api/customers/addAddress/${this.$route.params.id}`, this.newAddress)
          if(res.data.error) throw res.data.error

          if(res.data.success) {
            this.snack("Address added successfully!", "success");
            this.cus.addresses.push(this.newAddress);
            this.closeCustomAddressDialog();
          }
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      closeCustomAddressDialog(){
        this.newAddress = {};
        this.saveCustomAddressDialog = false;
      },
      async deleteAddress(i){
        try {
          this.loader = true
          let res = await axios.put(`${this.getEndpoint}/api/customers/removeAddress/${this.$route.params.id}/${i}`)
          if(res.data.error) throw res.data.error

          this.snack("Address Deleted 🎉", "success");
          this.cus.addresses.splice(i, 1);
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally{
          this.loader=false;
        }
      },
    }
  }
</script>
