<template>
    <v-card-text>
      <span class="d-flex flex-column justify-start" style="text-align: left;">
        <span class="d-flex flex-row align-space-between justify-space-between">
          <span class="d-flex flex-column">
            <h3>#{{order.id}}</h3>
            <v-chip x-small class="mr-3" :color="helpers.getStatusColor(order.status)">{{helpers.parseStatus(order.status)}}</v-chip>
          </span>
          <span class="d-flex flex-column">
            <span v-if="order.Customer">{{order.Customer.name}}</span>
            <span v-if="customer">{{customer}}</span>
            <b v-if="order.CSR" style="font-size: 11px;">CSR: {{order.CSR.firstName}} {{order.CSR.lastName&&order.CSR.lastName.length>0?order.CSR.lastName[0]:""}}</b>
            <b v-if="csr" style="font-size: 11px;">CSR: {{csr}}</b>
          </span>
          <span class="d-flex flex-column">
            <h4 v-if="order.metadata && order.metadata.grandTotal" class="ml-2">${{order.metadata.grandTotal}}</h4>
            <h4 v-else class="ml-2">$-</h4>
            <span v-if="order.orderItems&& order.orderItems.length>0">{{order.orderItems.length}} items.</span>
          </span>
          <v-btn x-small fab color="success" @click="()=>{$router.push({path: '/orders/view/'+order.id})}"><v-icon>mdi-chevron-right</v-icon></v-btn>
        </span>
      </span>
    </v-card-text>
</template>

<script>
import helpers from "../plugins/helpers"
export default {
  name: 'orderItem',
  props: {
    order: Object,
    customer: String,
    csr: String,
    source: String
  },
  data () {
    return {
      helpers: helpers
    }
  },
}
</script>